import React, { useEffect, useState } from 'react';
import { iconSmallSquare, iconDecorativeShapesLines, iconAnalysis, iconPrototype, iconDesign, iconDevelopment, iconTesterAndDelivery, iconWarrantyAndSupport } from '../../../assets/icons';
import { imageLaptop, imageSmartphone, imageTableLaptop, imageTableSmartphone } from '../../../assets/images';
import { useHistory } from 'react-router-dom';
import { CarouselServices, CarouselSkills } from '../../../components';
import './styles.scss';

export const Home = () => {

  let [width, setWidth] = useState(0)
  let [imageDevice, setImageDevice] = useState('');
  let [imageTableDevice, setImageTableDevice] = useState('');

  const history = useHistory();

  useEffect(() => {
    setWidth(window.screen.width)
    if(window.screen.width <= 780) {
      setImageDevice(imageSmartphone)
      setImageTableDevice(imageTableSmartphone)
    } else {
      setImageDevice(imageLaptop)
      setImageTableDevice(imageTableLaptop)
    }
  }, [])

  useEffect(()=> {
    let mediaqueryList = window.matchMedia("(max-width: 780px)");
    const mq = (EventoMediaQueryList) => {
      setWidth(window.screen.width)
      window.screen.width <= 780 
        ? setImageDevice(imageSmartphone) 
        : setImageDevice(imageLaptop)
    }
    mediaqueryList.addEventListener('change',  mq);

    return () => mediaqueryList.removeEventListener('change', mq)
  }, [])

  const link = (path) => {
    history.push(path)
    window.scroll({
        top: 0,
        left: 100,
        behavior: 'smooth'
    });
  }

  return (
    <>
      <div className="container-home">

        <section className="section-1"> 
          <div className="short-description">
            <h2 className="title">Hacemos lo extraordinario</h2>
            <p className="text">La <span className="text-blue">solución</span> que hará <span className="text-blue">crecer</span> tu negocio se expresa en <span className="text-blue">código</span> y se escribe en líneas</p>
          </div>
          <div className="buttons">
            <button className="button contact" onClick={() => link('/contact')} type="button">Contactar Ahora</button>
            <button className="button projects" onClick={() => link('/services')} type="button">Proyectos</button>
          </div>
          <div className="container-image-laptop">
            <img className="image-laptop" src={imageDevice === '' ? imageLaptop : imageDevice} alt="Imagen de Portatil"/>
            <img className="image-table-laptop" src={width <= 780 ? imageTableDevice : imageTableLaptop} alt="Imagen de Mesa"/>
          </div>
        </section>

        <section className="section-2">
          <h2 className="title-section-2">Lo que hacemos!</h2>
          <CarouselServices />
        </section>
        <CarouselSkills />
        <section className="section-4">
            <img className="icon-right" src={iconDecorativeShapesLines} alt="Icono de formas decorativas de lineas"/>
            <img className="icon-left" src={iconDecorativeShapesLines} alt="Icono de formas decorativas de lineas"/>
            <div className="contain">
                <img className="icon" src={iconSmallSquare} alt="Icono"/>
                <h2 className="title">¿Por qué elegirnos sobre la competencia?</h2>
                <p className="text">No somos solo desarrolladores, somos un <span className="text-midnight">equipo multidisciplinario</span>, compuesto por el <span className="text-midnight">área de diseño</span>, calidad, análisis y mejora continua, <span className="text-midnight">ingeniería de software y mercadotecnia</span>, que se encargaran de brindarte la mejor <span className="text-midnight">solución a tus necesidades</span></p>
                <p className="text">Esto lo logramos gracias a nuestra <span className="text-midnight">estandarización en el proceso de desarrollo.</span></p>
            </div>
        </section>
        
        <section className="section-5">
            <h1 className="title">Proceso</h1>
            <div className="container-cards">
                <div className="card">
                    <img className="image" src={iconAnalysis} alt="imagen de Analisis"/>
                    <h3 className="title">Análisis</h3>
                    <p className="text">Levantamiento y Análisis del desarrollo</p>
                </div>
                <div className="card">
                    <img className="image" src={iconPrototype} alt="imagen de Analisis"/>
                    <h3 className="title">Prototipo</h3>
                    <p className="text">Maquetaremos las ideas y se realizaran los ajustes necesarios hasta llegar al objetivo. Tu Satisfacción.</p>
                </div>
                <div className="card">
                    <img className="image" src={iconDesign} alt="imagen de Analisis"/>
                    <h3 className="title">Diseño</h3>
                    <p className="text">El diseño y funcionalidad, desarrollado por nuestros expertos, estará enfocada a generar la mejor experiencia de usuario.</p>
                </div>
                <div className="card">
                    <img className="image" src={iconDevelopment} alt="imagen de Analisis"/>
                    <h3 className="title">Desarrollo</h3>
                    <p className="text">El equipo de desarrolladores se encargaran de transformar los diseños en experiencia funcional.</p>
                </div>
                <div className="card">
                    <img className="image" src={iconTesterAndDelivery} alt="imagen de Analisis"/>
                    <h3 className="title">Pruebas y Entrega</h3>
                    <p className="text">Aquí probaremos que el desarrollo funcione correctamente, y que no se generen fallas en las funcionalidades.</p>
                </div>
                <div className="card">
                    <img className="image" src={iconWarrantyAndSupport} alt="imagen de Analisis"/>
                    <h3 className="title">Garantía y soporte</h3>
                    <p className="text">Todos nuestros desarrollos cuentan con garantía, por lo que puedes sentirte seguro que estaremos contigo después de la entrega del desarrollo.</p>
                </div>
            </div>
        </section>

        <section className="section-6">
          <img className="icon" src={iconSmallSquare} alt="Icono"/>
          <h2 className="title">¿Tu negocio y tú están listos para comenzar?</h2>
          <p className="text">No dudes en asesorarte sin costo</p>
          <button className="button" onClick={() => link('/contact')}>Contactar ahora</button>
        </section>
      </div>  
    </>
  )
}