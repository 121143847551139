import { imageIndustry4, imageIndustry4Small, imageIndustry4vsLean, imageIndustry4vsLeanSmall } from '../../UI/assets/images';
export const posts = [
    {
        title: 'Industria 4.0,  la revolución de los datos',
        shortDescription: 'Ante el avance del entorno digital, resulta indispensable comprender los actuales desafíos que presentan en una nueva era como lo es la cuarta Revolución Industrial, reconocida e identificada internacionalmente como la Industria 4.0.',
        description: `Es en esta nueva etapa que se nos presentan oportunidades para impulsar la competitividad, la eficiencia y la mejora continua de múltiples sectores como lo son el sector económico, el sector social e incluso el personal, rompiendo paradigmas de procesos obsoletos y anclados a una cultura reactiva, poco perceptiva a los cambios constantes de los mercados, las necesidades de los usuarios y a procesos preventivos.',

        Frente al fenómeno global, que representa la transformación digital, es necesario replantear cuestiones fundamentales, tales como…

        ¿Qué beneficios puede tener la industria 4.0 en mi negocio? 
        Los beneficios son incontables, pues cada uno de ellos suma nuevos elementos que mejoran cada proceso de tu negocio, como la obtención de DATOS, por medio de la digitalización de procesos industriales que conforman la cadena de valor, por la cual los procesos experimentan una mejora en su productividad, eficiencia, rendimiento y adaptabilidad, al contar con la información cada vez más específica de acuerdo a la búsqueda y en tiempo real.

        Estamos en un momento en el que cada vez más común que cada persona cuente con un dispositivo conectado a internet, volviéndose de suma importancia conocer la información que se genera en el mundo digital, lo que nos ayuda a conocer y analizar los datos al igual que sus tendencias permitiéndonos:

        • Mejorar el desempeño de productividad.
        • Mejorar comunicación en cada etapa de tu proceso.
        • Una producción masiva personalizada.
        • Análisis de datos en tiempo real para una pronta toma de decisiones.
        • Reducir los defectos y fallas en tu proceso.
        • Crear procesos de producción óptimos e integrados.
        
        ¿Cuáles son los principales desafíos de la Industria 4.0?
        La capacitación y el desarrollo e innovación de herramientas digitales adaptadas a los crecientes mercados, que día con día, exigen nuevos sistemas y procesos más personalizados en sus requerimientos, debe contar siempre con un proceso ampliamente optimizado, para consumir sus recursos de la manera más eficientemente posible. Por lo que es de suma importancia, un cambio de paradigmas en la transmisión y recepción de las necesidades en la cadena de valor. 
        
        
        En AI&SD sabemos que nos encontramos ante un punto de quiebre a nivel global, en el cual emergen grandes oportunidades y desafiantes retos que nos permitirán impulsar la creación de grandes ideas, de novedosos negocios y de proyectos inimaginables, con una nueva cultura de adopción de herramientas tecnológicas, colaboración y métodos de trabajo.

        Acércate a AI&SD y conoce nuestros servicios, permítenos asesorarte y brindarte un amplio enfoque de crecimiento estamos listos por trabajar contigo.
        `,
        created_at: {
            date: '06/28/21',
            user: 'Alejandro Gallarza'
        },
        image: imageIndustry4,
        smallImage: imageIndustry4Small,
    },

    {
        title: 'Industria 4.0, ¿El final de Lean Manufacturing? ',
        shortDescription: 'Hablar sobre la industria 4.0 se ha convertido en un tema cada vez más popular entre la gente, especialmente en el sector industrial, no es para nadie secreto que la industria manufacturera atraviesa una profunda transformación, para convertirla en la revolución de los datos.',
        description: `Al hablar sobre datos podemos nos referimos principalmente a 3 elementos.   Sensórica, ya que hoy convivimos día a día con sensores, que cada vez son más comunes en cada aspecto de nuestra vida, como en los refrigeradores, los automóviles, los dispositivos móviles etc. Almacenamiento masivo, con el volumen tan alto de información que se genera a cada segundo es indispensable almacenar de una forma eficiente esta información, a un bajo costo y con accesos en tiempo real a la información recolectada.  Por último, la inteligencia Artificial, que por medio de sus redes neuronales artificiales (RNA) son capaces de resolver problemas por medio de algoritmos que analizan, organizan y procesan datos de manera precisa.

        Lo que nos lleva a cuestionarnos, ¿Estos cambios desaparecerán a las herramientas existentes, como Lean Manufacturing?
        
        Desde su concepción e implementación, esta metodología ha ayudado a miles de empresas a mejorar sus procesos, sin embargo, el Lean tradicional por sí solo no alcanza a generar los resultados a los que, si puede llegar una empresa con IoT, por la cantidad de datos que es capaz de recolectar. Sin embargo, la metodología establecida por lean Manufacturing, sigue siendo la base del concepto por sobre el cual se toman las decisiones enfocadas en reducir desperdicios en los procesos, por lo que no son herramientas que se remplacen una por la otra, sino que implementadas después de un riguroso análisis pueden trabajar juntas potenciando sus beneficios.
        
        Una correcta implementación es la clave para cada herramienta funcione adecuadamente, por lo que resulta indispensable el enfoque y la técnica para la implementación de Lean y IoT, sin importar cuál de estas se esté implementando en el proceso, un buen PDCA (Plan, Do, Check, Act) es esencial para una implementación exitosa.
        
        Por lo tanto, en este momento las empresas no reemplazaran la metodología de lean, ya que seguirá sirviendo para identificar las áreas de oportunidad en los procesos, como los cuellos de botella o áreas con alto índice de desperdicio, solo que ahora los usuarios dispondrán de un mayor espectro de tecnologías y herramientas para no depender de una cultura reactiva sino predictiva, ir un paso adelante.
        

Acércate a AI&SD y conoce nuestros servicios, permítenos asesorarte y brindarte un amplio enfoque de crecimiento estamos listos por trabajar contigo.
        `,
        created_at: {
            date: '07/07/21',
            user: 'Alejandro Gallarza'
        },
        image: imageIndustry4vsLean,
        smallImage: imageIndustry4vsLeanSmall,
    }
]