import {imageServicesSection2, imageServicesSection3, imageServicesSection4, imageServicesSection5, imageServicesSection6, imageServicesSection7 } from '../../UI/assets/images';

export const services = [
    {
        title: 'CRM',
        shortDescription: 'Optimiza la gestión de tus recursos en un solo lugar, trabajando de una ordenada para darle seguimiento a tus clientes potenciales y a tu cartera de clientes actual, manteniendo a todos los usuarios con información actualizada.',
        description: '',
        image: imageServicesSection2
    },
    {
        title: 'ERP',
        shortDescription: 'Gestiona y monitoriza las distintas operaciones de tu negocio, para el control y optimización de los procesos del almacén, ventas, servicio al cliente entre otros. \n Integrando toda la gestión comercial de tu empresa.',
        description: '',
        image: imageServicesSection3
    },
    {
        title: 'e-Commerce',
        shortDescription: 'Tendrás la plataforma que necesitas para potencializar tus ventas, con las mejores herramientas para hacer crecer tu negocio en línea.',
        description: '',
        image: imageServicesSection4
    },
    {
        title: 'Diseño Digital',
        shortDescription: 'Nos aseguramos que nuestros diseños generen la mejor experiencia en el usuario, enfocándonos en las experiencias y emociones del usuario, generando así un gran impacto en el usuario.',
        description: '',
        image: imageServicesSection5
    },
    {
        title: 'Smart KPI´s',
        shortDescription: 'Te ayudamos a automatizar tus indicadores para un mejor seguimiento de tus métricos, análisis de datos en tiempo real, mayor recolección de datos, reducción de actividades, y optimización de procesos.',
        description: '',
        image: imageServicesSection6
    },
    {
        title: 'Plataformas de educación Virtual',
        shortDescription: 'Optimiza los recursos de la enseñanza, con esta herramienta que te permitirá llegar a tus alumnos de una forma más practica, y aumentar la exposición de tus usuarios.',
        description: '',
        image: imageServicesSection7
    }
]