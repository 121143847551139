import React from 'react';
import { posts } from '../../../../source';
import { imageBlogSection1 } from '../../../assets/images';
import { iconUserSmall, iconClockSmall } from '../../../assets/icons';
import { Link } from 'react-router-dom';
import './styles.scss';
import { generarUrl } from '../../../helpers/generarUrl';

export const Blog = () => {

    return (
        <>
            <main className="blog">
                <section className="section-1">
                    <div className="contain">
                        <img className="image" src={imageBlogSection1} alt="Imagen de Portatil"></img>
                        <h2 className="title">Blog</h2>
                        <div className="border"></div>
                        <p className="text">Entérate de cada una de las novedades tecnológicas que actualmente están dando de qué hablar.</p>
                    </div>
                </section>
                
                <section className="section-2">
                    {
                        posts.map((post, index) => {
                            const url = generarUrl(post.title)
                            return (
                                <article key={index + 1} className="article">
                                    <Link to={`/blog/${url}`}>
                                        <img className="image" src={post.smallImage} alt={`Imagen de ${post.title}`}/>
                                    </Link>
                                    <Link className="quit-line" to={`/blog/${url}`}>
                                        <h4 className="title">{post.title}</h4>
                                    </Link>
                                    <p className="text">{post.shortDescription}</p>
                                    <div className="made-by">
                                        <div className="contain">
                                            <img className="icon" src={iconUserSmall} alt="Icono de usuario"/>
                                            <span className="name">{post.created_at.user}</span>
                                        </div>
                                        <div className="contain">
                                            <img className="icon" src={iconClockSmall} alt="Icono de reloj"/>
                                            <span className="date">{post.created_at.date}</span>
                                        </div>
                                    </div>
                                </article>
                            )
                        })
                    }
                </section>
            </main>
        </>
    )
}