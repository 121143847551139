import React, { useRef, useState, useEffect } from 'react';
import { services } from '../../../source';
import Slider from "react-slick";
import "./styles.scss";

export const CarouselServices = () => {
    const [state, setState] = useState({
        nav1: null,
        nav2: null
      });

      const slider1 = useRef();
      const slider2 = useRef();

      useEffect(()=>{
        setState({
            nav1: slider1.current,
            nav2: slider2.current
          })
      }, []);

    let settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        slidesToShow: 3,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        responsive: [
            {
              breakpoint: 720,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false
              }
            }
          ]
      };

      let settings2 = {
        dots: true,
        arrows: false,
        fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      }

      const { nav1, nav2 } = state

    return (
        <div className="slider">
        <Slider asNavFor={nav2} ref={slider => (slider1.current = slider)} {...settings} >
            {
                services.map((service, index) => {
                    return (
                        <div key={index + 1}>
                            <article className="article">
                                <img className="image" src={service.image} alt={`Imagen de ${service.title}`}/>
                            </article>
                        </div>
                    )
                })
            }
        </Slider>
        <Slider asNavFor={nav1} ref={slider => (slider2.current = slider)} {...settings2}>
            {
                services.map((service, index) => {
                    return (
                        <div key={index + 1}>
                            <div className="description">
                                <h3 className="title">{service.title}</h3>
                                <p className="text">{service.shortDescription}</p>
                            </div>
                        </div>
                    )
                })
            }
        </Slider>
        </div>
    )
}