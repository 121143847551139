import React from "react";
import { Helmet } from "react-helmet"

export const Head = (props) => {
  const { title, description, path, image = "%PUBLIC_URL%/meta-home.jpg"} = props
  return (
    <Helmet>
    <title>AI&SD | {title}</title>
    <meta name="description" content= "Hacemos lo extraordinario! La solucion que hará crecer tu negocio se expresa en codigo y se escribe en líneas"/>
    <meta name="keywords" content="desarrollo, web, fullstack, aplicaciones, diseño, software, soluciónes" />
  
    <meta name="twitter:site" content="@AISD85019160"/>
    <meta name="twitter:card" content="summary_large_image"/>
    <meta name="twitter:title" content="AI&SD"/>
    <meta name="twitter:description" content={description}/>
    <meta name="twitter:image" content={image} />
  
    <meta property="og:site_name" content="AI&SD"/>
    <meta property="og:url" content="https://www.aisd.com.mx/"/>
    <meta property="og:type" content="website"/>
    <meta property="og:title" content="AI&SD"/>
    <meta property="og:description" content={description}/>
    <meta property="og:image" content={image}/>
  
    <meta itemprop="name" content="AI&SD"/>
    <meta itemprop="description" content={description}/>
    <meta itemprop="image" content={image}/>
   
    <link rel="canonical" href={`https://www.aisd.com.mx${path}`} />
    </Helmet>
  )
};