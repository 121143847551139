import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { Header, Footer, Home, Services, Blog, Contact, Post } from './containers';
import './styles.css';
import { Head } from '../components/head';
const Main = () => {
  const { pathname } = useLocation()
  
  const activeMenuFn = () => {
    const nav = document.getElementById('nav')
    nav.classList.remove('active-menu')
    // alert("hola")
  }

  return (
    <>
        <Switch>

          <Route path="/" exact>
            <Head title={'INICIO'} description={'Somos un equipo multidisciplinario, compuesto por el área de diseño, calidad, análisis y mejora continua, ingeniería de software y mercadotecnia, que se encargaran de brindarte la mejor solución a tus necesidades'} path={pathname} />
            <Header 
              active={'home'}
            />
            <div onClick={() => activeMenuFn()}>
              <Home/>
            </div>
            <Footer />
          </Route>

          <Route path="/services" exact>
            <Head title={'SERVICIOS'} description={'Contamos con un amplio repertorio de habilidades de las cuales tu negocio puede verse muy beneficiado'} path={pathname} />
            <Header 
              active={'services'}
            />
            <div onClick={() => activeMenuFn()}>
              <Services />
            </div>
            <Footer />
          </Route>

          <Route path="/blog" exact>
            <Head title={'BLOG'} description={'Entérate de cada una de las novedades tecnológicas que actualmente están dando de qué hablar'} path={pathname} />
            <Header 
              active={'blog'}
            />
            <div onClick={() => activeMenuFn()}>
              <Blog />
            </div>
            <Footer />
          </Route>

          <Route path="/blog/:idPost" exact>
            <Header 
              active={'blog'}
              bgNotTransparent={true}
            />
            <div onClick={() => activeMenuFn()}>
              <Post />
            </div>
            <Footer />
          </Route>

          <Route path="/contact" exact>
            <Head title={'CONTACTO'} description={'¿Tu negocio y tu están listos para comenzar?'} path={pathname} />
            <Header 
              active={'contact'}
            />
            <div onClick={() => activeMenuFn()}>
              <Contact />
            </div>
            <Footer />
          </Route>

        </Switch>
    </>
  )
}

export default Main;
