import React from 'react';
import { iconSmartphoneSmall, iconEmailSmall, iconFacebook, iconTwitter, iconInstagram, iconLinkedin } from '../../../assets/icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import './styles.scss';

export const Footer = () => {

    return (
        <>
            <div className="footer">
                <h2 className="title">Contáctanos</h2>
                <p className="text">La solución esta a un solo click de distancia. </p>
                <div className="border"></div>
                <div className="contact-information">
                    <div className="description">
                        <FontAwesomeIcon className="icon" icon={faMapMarkerAlt} />
                        <p className="text">José María González Arratia 105
                        Toluca, Estado de México</p>
                    </div>
                    <div className="description">
                        <img className="icon" src={iconSmartphoneSmall} alt="Icono de Celular"/>
                        <p className="text">+52 722 245 3986</p>
                    </div>
                    <div className="description">
                        <img className="icon" src={iconEmailSmall} alt="Icono de Correo"/>
                        <p className="text">contacto@aisd.com.mx</p>
                    </div>
                </div>
                <div className="social-media">
                    <a href={'https://www.facebook.com/aisdmx.project'} target="blank">
                        <img src={iconFacebook} className="icon" alt="Icono Facebook"/>
                    </a>
                    <a href={'https://twitter.com/AISD85019160'} target="blank">
                        <img src={iconTwitter} className="icon" alt="Icono Twitter"/>
                    </a>
                    <a href={'https://www.instagram.com/aisdmx/'} target="blank">
                        <img src={iconInstagram} className="icon" alt="Icono Instagram"/>
                    </a>
                    <a href={'https://www.linkedin.com/company/ai-sd'} target="blank">
                        <img src={iconLinkedin} className="icon" alt="Icono Linkedin"/>
                    </a>
                </div>
            </div>
        </>
    )
}