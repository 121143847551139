import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { posts } from '../../../../source';
import { iconUserSmall, iconClockSmall } from '../../../assets/icons';
import { Link } from 'react-router-dom';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import './styles.scss';
import { generarUrl } from '../../../helpers/generarUrl';
import { Head } from '../../../components/head';

export const Post = () => {

    const { pathname } = useLocation()

    let [post, setPost] = useState({title: '', shortDescription: '', description: '', created_at: {date: '', user: ''}, image: ''});

    const [otherPosts, setOtherPost] = useState([])

    const { idPost } = useParams();
    
    useEffect(() => {
        const index = posts.findIndex((post) => generarUrl(post.title) === idPost);
        const copyPost = posts[index]
        setPost(copyPost)
        const otherPosts = posts.filter((post) => generarUrl(post.title) !== idPost)
        setOtherPost(otherPosts)
    }, [idPost])

    const positionInitialScroll = () => {
        window.scroll({
            top: 0,
            left: 100,
            behavior: 'smooth'
        });
      }

    return (
        <>
            <Head title={post.title} description={'Entérate de cada una de las novedades tecnológicas que actualmente están dando de qué hablar'} path={pathname} image={post.image}/>
            <main className="post">
                <img className="image" src={post.image} alt={`Imagen de ${post.title}`} />
                <section className="section">
                    <div className="post">
                        <h1 className="title">{post.title}</h1>
                        <div className="container-created_at">
                            <div className="contain-created_at">
                                <img className="image-created_at" src={iconClockSmall} alt="Icono de Reloj" />
                                <span className="text">{post.created_at.date}</span>
                            </div>
                            <div className="contain-created_at">
                                <img className="image-created_at" src={iconUserSmall} alt="Icono de Usuario" />
                                <span className="text">{post.created_at.user}</span>
                            </div>
                        </div>
                        <p className="short-description">{post.shortDescription}</p>
                        <p className="description">{`${post.description}`}</p>
                        <div className="buttons-share">
                            <span>Compartir: </span>
                            <FacebookShareButton className="link-share" quote={post.title} url={`https://aisd.com.mx/blog/${idPost}`} >
                                <FontAwesomeIcon icon={faFacebookSquare} className="icon" />
                            </FacebookShareButton>
                            <TwitterShareButton className="link-share" title={post.title} url={`https://aisd.com.mx/blog/${idPost}`} >
                                <FontAwesomeIcon icon={faTwitter} className="icon" />
                            </TwitterShareButton>
                            <LinkedinShareButton className="link-share" title={post.title} summary={post.shortDescription} url={`https://aisd.com.mx/blog/${idPost}`} >
                                <FontAwesomeIcon icon={faLinkedin} className="icon" />
                            </LinkedinShareButton>
                        </div>
                    </div>
                    <div className="container-other-posts">
                        <h3 className="title">Tal vez estos temas te interesen</h3>
                        <div className="contain-other-posts">
                            {
                                otherPosts?.map((data, index) => {
                                    return (
                                        <div key={index + 1} className="post">
                                            <Link to={`/blog/${generarUrl(data.title)}`} onClick={() =>positionInitialScroll()}>
                                                <img className="image-other-post" src={data.image}alt={`Imagen de ${data.title}`}/>
                                            </Link>
                                            <Link className="contain-title" to={`/blog/${generarUrl(data.title)}`}onClick={() => positionInitialScroll()}>
                                                <h4 className="title">{data.title}</h4>
                                            </Link>
                                            <p className="text">{data.shortDescription}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}