import React, { useEffect, useState, useCallback } from 'react';
import { illuminatedLogo2, iconFacebook, iconTwitter, iconInstagram, iconLinkedin, iconMenuResponsive } from '../../../assets/icons';
import { Link } from 'react-router-dom';
import "./styles.scss";


export const Header = ({active, bgNotTransparent = false}) => {

  let [positionScrollY, setPositionScrollY] = useState(0);

  useEffect(() => {
    setPositionScrollY(window.pageYOffset)
    const doc = document.getElementById('header')
    if(window.pageYOffset === 0 || window.pageYOffset < 100) {
      doc.classList.add('initial-position-header')
    }
  }, [])

  const windowScroll = useCallback((e) => {
    const scrollY = window.pageYOffset;
    const doc = document.getElementById('header')
    if(doc !== null) {
      if(scrollY === 0 || scrollY < 100) {
        doc.classList.add('initial-position-header')
      } else {
        doc.classList.remove('initial-position-header')
        if(scrollY <= positionScrollY) {
          doc.classList.remove('desactive-header')
        } else {
          doc.classList.add('desactive-header')
        }
      }
    }
    setPositionScrollY(scrollY)
  }, [positionScrollY])

  useEffect(() => {
    window.addEventListener('scroll', windowScroll);

    return () => window.removeEventListener('scroll', windowScroll);
  }, [windowScroll])

  const activeMenuFn = () => {
    const nav = document.getElementById('nav')
    nav.classList.toggle('active-menu')
  }

  const positionInitialScroll = () => {
    window.scroll({
        top: 0,
        left: 100,
        behavior: 'smooth'
    });
    const nav = document.getElementById('nav')
    nav.classList.remove('active-menu')
  }

  return (
    <>
        <header id="header" className={`header ${bgNotTransparent ? 'bg-not-transparent' : ''}`}>
          <img src={illuminatedLogo2} className="logo" alt="logo AI&SD" />
          <img className="icon-menu-responsive" src={iconMenuResponsive} onClick={() => activeMenuFn()} alt="Icono de menu"/>
          <nav className="nav" id="nav">
            <ul className="main-menu">
              <div className="bg-icon-menu-responsive"></div>
              <Link className={`link-page remove-text-decoration-link ${active === 'home' ? 'active' : ''}`} to="/" onClick={() => positionInitialScroll()}>
                INICIO
              </Link>
              {/* <Link className={`link-page remove-text-decoration-link ${active === 'about' ? 'active' : ''}`}>
                NOSOTROS
              </Link> */}
              <Link className={`link-page remove-text-decoration-link ${active === 'services' ? 'active' : ''}`} to="/services" onClick={() => positionInitialScroll()}>
                SERVICIOS
              </Link>
              <Link className={`link-page remove-text-decoration-link ${active === 'blog' ? 'active' : ''}`} to="/blog" onClick={() => positionInitialScroll()}>
                BLOG
              </Link>
              <Link className={`link-page remove-text-decoration-link ${active === 'contact' ? 'active' : ''}`} to="/contact" onClick={() => positionInitialScroll()}>
                CONTACTO
              </Link>
            </ul>
            <div className="social-media-icons">
              <a href={'https://www.facebook.com/aisdmx.project'} target="blank">
                <img src={iconFacebook} className="icon" alt="Icono Facebook"/>
              </a>
              <a href={'https://twitter.com/AISD85019160'} target="blank">
                <img src={iconTwitter} className="icon" alt="Icono Twitter"/>
              </a>
              <a href={'https://www.instagram.com/aisdmx/'} target="blank">
                <img src={iconInstagram} className="icon" alt="Icono Instagram"/>
              </a>
              <a href={'https://www.linkedin.com/company/ai-sd'} target="blank">
                <img src={iconLinkedin} className="icon" alt="Icono Linkedin"/>
              </a>
            </div>
          </nav>
        </header>
    </>
  )
}

