import React from 'react';
import { skills } from '../../../source';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Slider from "react-slick";
import './styles.scss';

export const CarouselSkills = () => {

    let settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
      }

    return (
        <div className="container-carousel-skills">
            <h2 className="title">Las tecnologías que usamos</h2>
            <Slider {...settings}>
                {
                    skills.map((skill, index) => ( 
                        <FontAwesomeIcon key={index + 1} icon={skill.icon} className="icon" /> 
                    ))
                }
            </Slider>
        </div>
    )
}