const cleanArray = ( actual ) => {
  let newArray = [];
  for( let i = 0, j = actual.length; i < j; i++ ){
      if ( actual[ i ] ){
        newArray.push( actual[ i ] );
    }
  }
  return newArray;
}

export const generarUrl = (titulo) => {
  const normailzarTiulo = titulo.toLowerCase().normalize('NFD')
  .replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,"$1")
  .normalize().split(" ");

  return cleanArray(normailzarTiulo).join('-').replace(/([^a-z0-9-.])/g, "")
}