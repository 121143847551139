import React from 'react';
import { imageServicesSection1,imageSection2Contain1, imageSection2Contain2, imageHomeSection5Contain1, imageHomeSection5Contain2, imageHomeSection7Contain1, imageHomeSection7Contain2, } from '../../../assets/images';
import { iconSmallSquare, iconHomeSection4, iconHomeSection6, } from '../../../assets/icons';
import { Link } from 'react-router-dom';
import './styles.scss';

export const Services = () => {

    const positionInitialScroll = () => {
        window.scroll({
            top: 0,
            left: 100,
            behavior: 'smooth'
        });
    }

    return (
        <>
            <main className="services">
                <section className="section-1">
                    <div className="contain">
                        <img className="image" src={imageServicesSection1} alt="Imagen de Portatil"></img>
                        <h2 className="title">Servicios</h2>
                        <div className="border"></div>
                        <p className="text">Contamos con un amplio repertorio de habilidades de las cuales tu negocio puede verse muy beneficiado.</p>
                    </div>
                </section>

                
                <section className="section-2">
                <img className="icon" src={iconSmallSquare} alt="Icono"/>
                <h2 className="title"> Tenemos la mejor Solución Tecnológica para hacer crecer tu negocio</h2>
                <p className="text">Te asesoramos durante todo el proceso con las mejores herramientas tecnológicas.</p>
                </section>

                <section className="section-3">
                <h2 className="title">Nuestros Productos</h2>
                <div className="contain-1">
                    <img className="image" src={imageSection2Contain1} alt="Imagen"/>
                    <div className="description">
                    <h4 className="title">Diseño de Interfaces UI y UX</h4>
                    <p className="text">Te ayudaremos a desarrollar la mejor experincia de usuario, definiendo la forma, funcionalidad, utilidad, ergonomía y la imagen de tu marca, para lograr un impacto en los usuarios.</p>
                    </div>
                </div>
                <div className="contain-2">
                    <div className="description">
                    <h4 className="title">Desarrollo Web</h4>
                    <p className="text">Nuestro equipo te ayudara con las mejores herramientas, a optimizar tu desarrollo web, creando sitios únicos, eficientes, escalables e inteligentes, con los que podrás llegar a mas gente gracias a nuestra experiencia en el manejo de SEO, lo que potencializará tu negocio.</p>
                    </div>
                    <img className="image" src={imageSection2Contain2} alt="Imagen"/>
                </div>
                </section>
                
                <section className="section-4">
                <img className="image" src={iconHomeSection4} alt="Imagen"/>
                <div></div>
                <div className="contain">
                    <h4 className="title">Aplicaciones Móviles</h4>
                    <p className="text">Para el desarrollo de una aplicación es importante tener un panorama claro sobre el alcance de la funcionalidad que se buscan en la aplicación, para saber qué tipo de app se adapta mejor, analizando  sus ventajas y desventajas, y que mejor que hacerlo con un equipo que te dará las mejores opciones en lenguajes de programación para ayudarte a solucionar o mejorar los procesos que actualmente tienes en tu negocio.</p>
                    <p className="text">Queremos asesorarte, seguro trabajaremos juntos.</p>
                </div>
                </section>
                
                <section className="section-5">
                <div className="contain-1">
                    <div className="description">
                    <h4 className="title">IOT Internet de las cosas</h4>
                    <p className="text">Creamos ecosistemas digitales de interconexión entre tus proceso y actividades cotidianas para que puedas tener control de ellas en tiempo real.</p>
                    </div>
                    <img className="image" src={imageHomeSection5Contain1} alt="Imagen"/>
                </div>
                <div className="contain-2">
                    <img className="image" src={imageHomeSection5Contain2} alt="Imagen"/>
                    <div className="description">
                    <h4 className="title">Realidad Aumentada</h4>
                    <p className="text">Te ayudamos a crear tecnologías que permiten a tus usuarios visualizar parte del mundo real a través  de un dispositivo tecnológico con información gráfica añadida por medio del dispositivo. </p>
                    </div>
                </div>
                </section>

                <section className="section-6">
                <img className="image" src={iconHomeSection6} alt="Imagen"/>
                <div className="contain">
                    <h4 className="title">Cloud Computing</h4>
                    <p className="text">¿Necesitas gestionar tu información de una manera más eficiente? con esta herramienta te ayudaremos a realizar un mejor manejo de tus datos.</p>
                </div>
                </section>

                <section className="section-7">
                <div className="contain-1">
                    <div className="description">
                    <h4 className="title">Data Analytics</h4>
                    <p className="text">El principio de la mejora continua son los datos, Todo lo que se mide se puede mejorar, y con esta herramienta te ayudaremos a conseguir medir tus datos y cambiar de un cultura reactiva a una cultura predictiva.</p>
                    </div>
                    <img className="image" src={imageHomeSection7Contain1} alt="Imagen"/>
                </div>
                <div className="contain-2">
                    <img className="image" src={imageHomeSection7Contain2} alt="Imagen"/>
                    <div className="description">
                    <h4 className="title">Inteligencia Artificial</h4>
                    <p className="text">Es la combinación de algoritmos.....  Blah, Blah, Blah!! Dejanos lo complicado a nosotros, y obten los mejores beneficios de tener la inteligencia artificial en tus desarrollos, lo que te puede permitir generear recomendaciones a tus usuarios dependiendo sus busquedas anteriores o compras previas entre opciones de uso de la web.</p>
                    </div>
                </div>
                </section>

                <section className="section-8">
                    <img className="icon" src={iconSmallSquare} alt="Icono"/>
                    <h2 className="title">¿Tu negocio y tu están listos para comenzar?</h2>
                    <p className="text">No dudes en asesorarte sin costo</p>
                    <Link to="/contact">
                        <button className="button" onClick={() => positionInitialScroll()}>Contactar ahora</button>
                    </Link>
                </section>
            </main>
        </>
    )
}