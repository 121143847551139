import React, { useState, useEffect, useRef } from 'react';
import { iconDecorativeShapesLines } from '../../../assets/icons';
import emailjs from 'emailjs-com'
import './styles.scss';

export const Contact = () => {

    let [form, setForm] = useState({name: '', email: '', service: '', message: ''})

    const refFormContact = useRef()

    const handleChange = (event, name) => {
        setForm({[name]: event.target.value})
    }

    useEffect(() => {
        emailjs.init('user_uJJzFPGqkjCdFg7Sqz0Dr')
    }, [])

    const sendEmailContact = (event) => {
        event.preventDefault()

        emailjs.sendForm('default_service', 'template_ducyrrn', '#form-contact' )
        .then(function(response) {
            console.log('SUCCESS!', response.status, response.text);
            refFormContact.current.reset()
            setForm({service: '', message: ''})
            const messageSend = document.getElementById('message-send')
            messageSend.classList.add('message-send-active')
            setTimeout(() => {
                messageSend.classList.remove('message-send-active')
            }, 3000)
        }, function(err) {
            console.log('FAILED...', err);
        });
    }

    return (
        <>
            <main className="contact">
                <div id="message-send" className="message-send">Mensaje Enviado</div>
                <img className="icon-right" src={iconDecorativeShapesLines} alt="Icono de formas decorativas de lineas"/>
                <img className="icon-left" src={iconDecorativeShapesLines} alt="Icono de formas decorativas de lineas"/>
                <section className="section">
                    <h1 className="title">Ponte en contacto</h1>
                    <div className="border"></div>
                    <p className="text">
                        {`¿Tu negocio y tu están listos para comenzar?
                        No dudes en asesorarte sin costo`}
                    </p>
                    <form id="form-contact" className="form" ref={refFormContact} onSubmit={sendEmailContact}>
                        <div className="container-input">
                            <input className="input" value={form.name} onChange={(e) => handleChange(e, 'name')} id="user_name" name="user_name" placeholder="Nombre"/>
                            <span className="required">*</span>
                        </div>
                        <div className="container-input">
                            <input className="input" type="email" value={form.email} onChange={(e) => handleChange(e, 'email')} id="user_email" name="user_email" placeholder="Correo eléctronico"/>
                            <span className="required">*</span>
                        </div>
                        <div className="container-select">
                            <label className="label">Área en la que estas interesado(a)</label>
                            <select className="select" value={form.service} onChange={(e) => handleChange(e, 'service')} name={"user_service"}>
                                <option hidden value="Default">Selecciona</option>
                                <option value="Diseño Digital">Diseño UX UI</option>
                                <option value="Desarrollo Web">Desarrollo Web</option>
                                <option value="e commerce">e Commerce</option>
                                <option value="crm">Plataforma CRM</option>
                                <option value="erp">Plataforma ERP</option>
                                <option value="iot">IoT Internet de las cosa</option>
                                <option value="Realidad Aumentada">Realidad Aumentada</option>
                                <option value="Inteligencia Artificial">Inteligencia Artificial </option>

                            </select>
                        </div>
                        <div className="container-textarea">
                            <textarea className="textarea" value={form.message} onChange={(e) => handleChange(e, 'message')} id="message" name="message" placeholder="Mensaje"/>
                            <span className="required">*</span>
                        </div>
                        <div className="container-checkbox">
                            <input className="checkbox" id="terms-and-conditions" name="terms-and-conditions" type="checkbox" required/>
                            <label className="label" htmlFor="terms-and-conditions">He leído y Acepto los <strong>términos y condiciones</strong></label>
                        </div>
                        <button type="submit" className="button">Enviar</button>
                    </form>
                </section>
            </main>
        </>
    )
}