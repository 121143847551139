import { faHtml5, faCss3Alt, faJs, faReact, faNodeJs, faPython } from "@fortawesome/free-brands-svg-icons";

export const skills = [
    {
        name: 'HTML',
        icon: faHtml5
    },
    {
        name: 'CSS',
        icon: faCss3Alt
    },
    {
        name: 'Javascript',
        icon: faJs
    },
    {
        name: 'ReactJs',
        icon: faReact
    },
    {
        name: 'NodeJs',
        icon: faNodeJs
    },
    {
        name: 'Python',
        icon: faPython
    },
]